@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300&display=swap');

:root {
  --accent: #f44336;
  --invert: 0;
  --card-hover-shadow: 0px 18px 36px -18px rgba(0,0,0,0.1),0px 30px 45px -30px rgba(50,50,93,0.25);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(250 250 249/1);
}

.above {
  z-index: 5 !important;
}

img {
  max-width: 100%;
  max-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  text-align: left;
}

a {
  color: var(--bs-body-color) !important;
  position: relative;
  text-decoration: none !important;

  &:not(.muted) {
    &:after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--bs-body-color);
      opacity: 0.5;
    }
  }
}

@font-palette-values --ThreeDee {
  font-family: Nabla;
  base-palette: 3;
}

@font-palette-values --ThreeDeeAlternate {
  font-family: Nabla;
  base-palette: 2;
}

@font-palette-values --ThreeDeeHighlight {
  font-family: Nabla;
  base-palette: 4;
}

hr {
  display: block;
  clear: both;
  border: 0;
  width: 100%;
  height: 0.4em;
  opacity: 1;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 119 6'%3E%3Cpath d='M119 3.8c-60 2.5-33.5-7-119 0' fill='none' stroke='%23eee' stroke-width='1.5'/%3E%3C/svg%3E") center/5em 100% repeat-x;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

[cmdk-overlay] {
  background-color: rgb(0 0 0 / 30%);
  position: fixed;
  inset: 0px;
  z-index: 5;
}

[cmdk-root] {
  width: 640px;
  max-width: calc(100% - 32px);
  backdrop-filter: blur(15px);
  background: rgb(255 255 255 / 77%);
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
}

[cmdk-item] {
  content-visibility: auto;
  color: black;

  cursor: pointer;
  height: 48px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 16px;
  user-select: none;
  transition: all .2s ease-out;
  position: relative;
  opacity: .6;
  z-index: 11;

  & + [cmdk-item] {
    margin-top: 4px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 12px;
  }
}

[cmdk-list] {
  overflow: auto;
  overscroll-behavior: contain;
  transition: 100ms ease;
  transition-property: height;
  padding: 7px;
}

[cmdk-group-heading] {
  user-select: none;
  font-size: 12px;
  color: #6f6f6f;
  padding: 0 8px;
  display: flex;
  align-items: center;
  margin-block: 8px;
  z-index: 11;
  position: relative;
}

[cmdk-empty] {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  white-space: pre-wrap;
  color: var(--gray11);
}